import { Routes } from '@angular/router';

export const COMPONENT_PLAYGROUND_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('@dev-tools').then((c) => c.ComponentPlaygroundComponent),
    children: [
      { path: '', redirectTo: 'accordion', pathMatch: 'full' },
      {
        path: 'accordion',
        loadComponent: () => import('@dev-tools').then((c) => c.CpAccordionComponent),
      },
      {
        path: 'actionable-selector',
        loadComponent: () => import('@dev-tools').then((c) => c.CpActionableSelectorComponent),
      },
      {
        path: 'alerts',
        loadComponent: () => import('@dev-tools').then((c) => c.CpAlertComponent),
      },
      {
        path: 'async-selector',
        loadComponent: () => import('@dev-tools').then((c) => c.CpAsyncSelectorComponent),
      },
      {
        path: 'autocomplete',
        loadComponent: () => import('@dev-tools').then((c) => c.CpAutocompleteComponent),
      },
      {
        path: 'avatar',
        loadComponent: () => import('@dev-tools').then((c) => c.CpAvatarComponent),
      },
      {
        path: 'badges',
        loadComponent: () => import('@dev-tools').then((c) => c.CpBadgesComponent),
      },
      {
        path: 'button',
        loadComponent: () => import('@dev-tools').then((c) => c.CpButtonComponent),
      },
      {
        path: 'card-list',
        loadComponent: () => import('@dev-tools').then((c) => c.CpCardListComponent),
      },
      {
        path: 'carousel',
        loadComponent: () => import('@dev-tools').then((c) => c.CpCarouselComponent),
      },
      {
        path: 'charts',
        loadComponent: () => import('@dev-tools').then((c) => c.CpChartsComponent),
      },
      {
        path: 'chips',
        loadComponent: () => import('@dev-tools').then((c) => c.CpChipsComponent),
      },
      {
        path: 'colors',
        loadComponent: () => import('@dev-tools').then((c) => c.CpColorsComponent),
      },
      {
        path: 'dashboard',
        loadComponent: () => import('@dev-tools').then((c) => c.CpDashboardComponent),
      },
      {
        path: 'date-picker',
        loadComponent: () => import('@dev-tools').then((c) => c.CpDatePickerComponent),
      },
      {
        path: 'dialog',
        loadComponent: () => import('@dev-tools').then((c) => c.CpDialogComponent),
      },
      {
        path: 'directives',
        loadComponent: () => import('@dev-tools').then((c) => c.CpDirectivesComponent),
      },
      {
        path: 'divider',
        loadComponent: () => import('@dev-tools').then((c) => c.CpDividerComponent),
      },
      {
        path: 'drawer',
        loadComponent: () => import('@dev-tools').then((c) => c.CpDrawerComponent),
      },
      {
        path: 'empty-state',
        loadComponent: () => import('@dev-tools').then((c) => c.CpEmptyStateComponent),
      },
      {
        path: 'field',
        loadComponent: () => import('@dev-tools').then((c) => c.CpFieldComponent),
      },
      {
        path: 'loading',
        loadComponent: () => import('@dev-tools').then((c) => c.CpLoadingComponent),
      },
      {
        path: 'logo',
        loadComponent: () => import('@dev-tools').then((c) => c.CpLogoComponent),
      },
      {
        path: 'mat-chip-tab',
        loadComponent: () => import('@dev-tools').then((c) => c.CpMatChipTabComponent),
      },
      {
        path: 'mat-range-input',
        loadComponent: () => import('@dev-tools').then((c) => c.CpMatRangeInputComponent),
      },
      {
        path: 'menu',
        loadComponent: () => import('@dev-tools').then((c) => c.CpMenuComponent),
      },
      {
        path: 'no-data',
        loadComponent: () => import('@dev-tools').then((c) => c.CpNoDataComponent),
      },
      {
        path: 'pipes',
        loadComponent: () => import('@dev-tools').then((c) => c.CpPipesComponent),
      },
      {
        path: 'retry-component',
        loadComponent: () => import('@dev-tools').then((c) => c.CpRetryComponent),
      },
      {
        path: 'search',
        loadComponent: () => import('@dev-tools').then((c) => c.CpSearchComponent),
      },
      {
        path: 'selection-controls',
        loadComponent: () => import('@dev-tools').then((c) => c.CpSelectionControlsComponent),
      },
      {
        path: 'slider',
        loadComponent: () => import('@dev-tools').then((c) => c.CpSliderComponent),
      },
      {
        path: 'slide-toggle',
        loadComponent: () => import('@dev-tools').then((c) => c.CpSlideToggleComponent),
      },
      {
        path: 'spacing',
        loadComponent: () => import('@dev-tools').then((c) => c.CpSpacingComponent),
      },
      {
        path: 'status',
        loadComponent: () => import('@dev-tools').then((c) => c.CpStatusComponent),
      },
      {
        path: 'steps',
        loadComponent: () => import('@dev-tools').then((c) => c.CpStepsComponent),
      },
      {
        path: 'tables',
        loadComponent: () => import('@dev-tools').then((c) => c.CpTablesComponent),
      },
      {
        path: 'tabs',
        loadComponent: () => import('@dev-tools').then((c) => c.CpTabsComponent),
      },
      {
        path: 'time-picker',
        loadComponent: () => import('@dev-tools').then((c) => c.CpTimePickerComponent),
      },
      {
        path: 'toast',
        loadComponent: () => import('@dev-tools').then((c) => c.CpToastComponent),
      },
      {
        path: 'tooltip',
        loadComponent: () => import('@dev-tools').then((c) => c.CpTooltipComponent),
      },
      {
        path: 'typography',
        loadComponent: () => import('@dev-tools').then((c) => c.CpTypographyComponent),
      },
      {
        path: 'upload-file',
        loadComponent: () => import('@dev-tools').then((c) => c.CpUploadFileComponent),
      },
    ],
  },
];
