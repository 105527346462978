import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { SafeAny } from '@core/types';

@Component({
  selector: 'app-cp-mat-slide-toggle',
  imports: [MatSlideToggle],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div class=" flex flex-col gap-10">
    <mat-slide-toggle hideIcon [checked]="true" (change)="onToggle($event)" color="primary"
      >Primary
    </mat-slide-toggle>

    <mat-slide-toggle hideIcon [checked]="true" (change)="onToggle($event)" color="accent"
      >Accent
    </mat-slide-toggle>

    <mat-slide-toggle hideIcon [checked]="true" (change)="onToggle($event)" color="warn"
      >Warn
    </mat-slide-toggle>

    <mat-slide-toggle
      [checked]="false"
      (change)="onToggle($event)"
      color="primary"
    ></mat-slide-toggle>

    <mat-slide-toggle
      [checked]="false"
      (change)="onToggle($event)"
      color="accent"
    ></mat-slide-toggle>

    <mat-slide-toggle [checked]="false" (change)="onToggle($event)" color="warn"></mat-slide-toggle>
  </div>`,
  styles: ``,
})
export class CpSlideToggleComponent {
  onToggle(event: SafeAny) {
    if (event.checked) {
      this.onSlide();
    } else {
      this.onSlideBack();
    }
  }

  onSlide() {
    alert('Slide function called!');
    console.log('Slide function called!');
  }

  onSlideBack() {
    alert('SlideBack function called!');
    console.log('SlideBack function called!');
  }
}
