import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchBoxComponent } from '@shared/components';

@Component({
  selector: 'app-cp-search',
  imports: [SearchBoxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <app-search-box label="Search" prefix /> `,
  styles: ``,
})
export class CpSearchComponent {}
